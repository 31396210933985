import { ChangeEvent, FC } from 'react';
import { Col, Radio, Row, RadioChangeEvent } from 'antd';
import Input from '../../../components/input/Input';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';
import Textarea from '../../../components/textarea/Textarea';

interface IProps {
  onChangeDetails: (name: string, value: string) => void;
  inputValues: Pick<
    ILeadDto,
    | 'swissWorkPermit'
    | 'permitType'
    | 'salaryRateNoVAT'
    | 'salaryType'
    | 'currency'
    | 'workingDaysHours'
    | 'comments'
    | 'notes'
  >;
  getError: (fileName: string) => string;
}

const WorkInfoComponent: FC<IProps> = ({
  inputValues,
  onChangeDetails,
  getError,
}) => {
  const {
    swissWorkPermit,
    permitType,
    salaryRateNoVAT,
    salaryType,
    currency,
    workingDaysHours,
    comments,
    notes = '',
  } = inputValues;

  const onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | RadioChangeEvent,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const name = e.target.name!;
    const value = e.target.value;

    onChangeDetails(name, value);
  };

  return (
    <Col span={12} className="work-info">
      <h3>Work information</h3>

      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col>
          <span>Swiss work permit:</span>
        </Col>
        <Col>
          <Radio.Group
            name="swissWorkPermit"
            onChange={onChange}
            value={swissWorkPermit}>
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
            <Radio value="I am a Swiss citizen">Swiss citizen</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="permitType"
            value={permitType}
            onChange={onChange}
            label="Work permit type"
            errorMessage={getError('permitType')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <Input
            inputType="number"
            name="salaryRateNoVAT"
            value={salaryRateNoVAT}
            onChange={onChange}
            label="Bill rate"
            errorMessage={getError('salaryRateNoVAT')}
          />
        </Col>
        <Col span={8}>
          <Input
            name="salaryType"
            value={salaryType}
            onChange={onChange}
            label="Salary type"
            errorMessage={getError('salaryType')}
          />
        </Col>
        <Col span={8}>
          <Input
            name="currency"
            value={currency}
            onChange={onChange}
            label="Currency"
            errorMessage={getError('currency')}
          />
        </Col>
      </Row>

      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col span={8}>
          <span>Work schedule</span>
        </Col>
        <Col span={16}>
          <Input
            name="workingDaysHours"
            value={workingDaysHours}
            onChange={onChange}
            label=""
            errorMessage={getError('workingDaysHours')}
          />
        </Col>
      </Row>

      <h3 className="comment-section-title">Comments section</h3>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            name="comments"
            value={comments}
            onChange={onChange}
            label="Consultant Comments"
            errorMessage={getError('comments')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Textarea
            name="notes"
            value={notes}
            onChange={onChange}
            label="Numeriq Notes"
            errorMessage={getError('notes')}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default WorkInfoComponent;
