import React, {
  ChangeEvent,
  FC,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Switch,
  Select,
  notification,
} from 'antd';
import { Link } from 'react-router-dom';
import CrossIcon from '../../../../assets/icons/CrossIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import WarningIcon from '../../../../assets/icons/WarningIcon';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Input from '../../../../components/input/Input';
import {
  IClientContract,
  IWorkContract,
} from '../../../../redux/reducers/onboarding/types';
import { shippingMethodsList } from '../../../../common/constants/shippingMethodsList';
import {
  IInitClientContract,
  TInitClientContractType,
  initClientContract,
} from './helper';
import { clientApi } from '../../../../common/api/services/ClientApi/clientApi';
import { capitalizeString } from '../../../../common/utils/stringsUtil';
import ErrorIcon from '../../../../assets/icons/ErrorIcon';
import ILabelInValue from '../../../../common/types/ILabelInValue';
import {
  paymentTermsList,
  TPaymentTermsList,
} from '../../../../common/constants/paymentTermsList';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  clearSelectedClient,
  initialClientContact,
} from '../../../../redux/reducers/clients/sliceReducer';
import { getClientById } from '../../../../redux/reducers/clients/asyncThunkActions';
import {
  updateOnboardingClientContract,
  updateOnboardingClientContractShippingDetails,
} from '../../../../redux/reducers/onboarding/sliceReducer';
import PopoverHelpInfo from '../../../../components/popover-help-info/PopoverHelpInfo';
import InfoMessage from '../../../../components/info-messages/InfoMessage';
import CommonDatePicker from '../../../../components/date-picker/CommonDatePicker';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { systemInfoApi } from '../../../../common/api/services/SystemInfoApi/SystemInfoApi';
import {
  commonMonthYearSpaceFormat,
  commonYearDateFormat,
} from '../../../../common/constants/dateConstants';
import { openErrorNotification } from '../../../../components/notification/Notification';
import moment from '../../../../common/constants/moment';
import { IVATs } from '../../../../common/api/services/SystemInfoApi/types/ISystemInfo';
import { convertSelectedIdx } from '../../../../common/utils/onboardingSortHelper';
import Textarea from '../../../../components/textarea/Textarea';

const wageTypeOptions = ['Fixed Monthly Wage', 'Timesheet'];
const timesheetSubmissionArray = ['Numeriq portal', 'Alternative way'];

const { Option } = Select;

interface IProps {
  clientContract: IClientContract;
  workContract: IWorkContract;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessages: any;
  isAdmin: boolean;
  getError: (fieldName: string, isGroup?: boolean) => string;
  onChange: (event: ChangeEvent<HTMLInputElement> | RadioChangeEvent) => void;
  onChangeDate: (fieldName: string) => (date: Date | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdown: (fieldName: string) => (data: any) => void;
  onChangeShippingDetails: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeSwitch: (fieldName: 'indefinite') => void;
  onChangeWorkType: (event: RadioChangeEvent) => void;
  onSelectCurrentClient: (
    index: number,
  ) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  resetFields: (fieldName: string, fieldValue: string | null) => void;
  onAddNewSignatory: () => void;
  onAddNewInvoicery: () => void;
  onAddNewReminder: () => void;
  onDeleteSignatory: (index: number) => () => void;
  onDeleteInvoicery: (index: number) => () => void;
  onDeleteReminder: (index: number) => () => void;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onChangeSignatoryByIndex: (index: number) => (data: any) => void;
  onChangeInvoiceryByIndex: (index: number) => (data: any) => void;
  onChangeReminderByIndex: (index: number) => (data: any) => void;
  onChangeContractContact: (index: number, value: string, name: string) => void;
  onChangeValuesByIdxAndName: (
    index: number,
    rootName: TInitClientContractType,
    value: Record<string, string>,
  ) => void;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

const customOption = (array: ILabelInValue[]) => {
  if (!array.length) return null;
  return array.map((item) => {
    const values = item.value.split('||-||');
    return (
      <Option value={item.value} key={item.value}>
        {`${values[0]} ${values[1]}`}
        <br />
        {values[2]}
        {values[3] && (
          <>
            <br />
            {values[3]}
          </>
        )}
      </Option>
    );
  });
};

const paymentTermOption = (
  clientPaymentTerm: TPaymentTermsList,
  clientPaymentTermRange: string,
) => {
  return paymentTermsList.map((item) => {
    const isCustomText = item === clientPaymentTerm;
    const termLabel = isCustomText ? `${item} ${clientPaymentTermRange}` : item;
    return { label: termLabel, value: item };
  });
};

const ClientContractTabInformationSection: FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const [vatRates, setVatRates] = useState<IVATs>([]);
  const clientPaymentTerm = useAppSelector(
    (state) => state.clients.selectedClient.paymentTerm,
  );
  const clientPaymentTermRange = useAppSelector(
    (state) => state.clients.selectedClient.paymentTermRange,
  );
  const clientContractOptions = useAppSelector(
    (state) => state.clients.selectedClient.clientContacts,
  );
  const client = useAppSelector((state) => state.clients.selectedClient);

  const {
    clientContract,
    workContract,
    isAdmin,
    errorMessages,
    getError,
    onChange,
    onChangeDate,
    onChangeDropdown,
    onChangeShippingDetails,
    onChangeSwitch,
    onChangeWorkType,
    onSelectCurrentClient,
    onAddNewSignatory,
    onAddNewInvoicery,
    onAddNewReminder,
    onDeleteSignatory,
    onDeleteInvoicery,
    onDeleteReminder,
    onChangeSignatoryByIndex,
    onChangeInvoiceryByIndex,
    onChangeReminderByIndex,
    onChangeValuesByIdxAndName,
    onChangeContractContact,
  } = props;

  const { selectedClientIdx, clients } = clientContract;
  const clientCount = clients.length;
  const { initialClientContractOrder } = useAppSelector(
    (state) => state.onboarding,
  );

  const convertedSelectedIdx = convertSelectedIdx(
    clients,
    initialClientContractOrder,
    selectedClientIdx,
  );

  const {
    shippingMethod = client?.shippings.length > 0
      ? client?.shippings[0].method || ''
      : '',
    shippingMethodDetails: shippingMethods = client?.shippings.length > 0
      ? client?.shippings[0].details
      : '',
    workType = 'Full time',
    workHoursPerWeek = '',
    paymentTerm = '',
    paymentTermRange = '',
    reimbursableExpenses = true,
    startDate = '',
    endDate = '',
    indefinite = true,
    signatories: signatoriesFromState,
    invoiceries: invoiceriesFromState,
    reminders: remindersFromState,
    clientContacts: clientContactsFromState,
    wageType,
    timesheetSubmission,
    clientOrContractComment,
    contractComment,
    vatRate,
    clientId,
  } = clients[selectedClientIdx];

  const signatories =
    signatoriesFromState && signatoriesFromState.length > 0
      ? signatoriesFromState
      : [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ];

  const invoiceries =
    invoiceriesFromState && invoiceriesFromState.length > 0
      ? invoiceriesFromState
      : [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ];

  const reminders =
    remindersFromState && remindersFromState.length > 0
      ? remindersFromState
      : [
          {
            name: '',
            lastName: '',
            email: '',
          },
        ];

  const clientContacts =
    clientContactsFromState && clientContactsFromState?.length > 0
      ? clientContactsFromState
      : [initialClientContact];

  const shippingMethodDetails = shippingMethods || {
    postalAddress: '',
    firstName: '',
    lastName: '',
    portalUrl: '',
  };

  const {
    postalAddress = '',
    firstName = '',
    lastName = '',
    portalUrl = '',
  } = shippingMethodDetails;

  const isShowShippingMethodDetails =
    shippingMethod === 'by post' || shippingMethod === 'portal';

  const { companies } = workContract;

  const checkTab = (index: number) => {
    if (
      errorMessages &&
      Object.keys(errorMessages).filter((item) =>
        item.startsWith(`data.clients[${index}]`),
      ).length > 0
    )
      return 'error-tab';
    return '';
  };

  const onChangeTextarea = (value: string) => {
    dispatch(
      updateOnboardingClientContract({ clientOrContractComment: value }),
    );
  };

  const refPrevInput = useRef<string>('');

  const [listOfClients, setListOfClients] = useState<IInitClientContract>({
    ...initClientContract,
  });

  useEffect(() => {
    if (!clientId || !isAdmin) return;

    dispatch(getClientById(clientId));
    const getClient = async () => {
      const state: Partial<IInitClientContract> = {};
      try {
        const response = await clientApi.getClientById(clientId);
        state.response = response;
        if (response?.vat) {
          state.vatRate = response.vat?._id;
          if (!vatRate && response.vat?._id) {
            onChangeDropdown('vatRate')(response.vat?._id);
          }
        }
        const {
          signatories: respSignatories = [],
          invoiceries: respInvoiceries = [],
          reminders: respReminders = [],
          clientContacts: respClientContacts = [],
        } = response || {};
        state.signatories = signatories.map(
          ({ name = '', lastName = '', email = '' }) => {
            const found = respSignatories.find(
              (item) =>
                item.name === name &&
                item.lastName === lastName &&
                item.email === email,
            );
            return {
              selectedName: found
                ? {
                    label: name,
                    value: `${name}||-||${lastName}||-||${email}`,
                  }
                : null,
              searchName: name,
            };
          },
        );

        state.listOfSignatoryNames = respSignatories
          .map(({ name = '', lastName = '', email = '' }) => ({
            label: name,
            value: `${name}||-||${lastName}||-||${email}`,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));

        state.invoiceries = invoiceries.map(
          ({ name = '', lastName = '', email = '' }) => {
            const found = respInvoiceries.find(
              (item) =>
                item.name === name &&
                item.lastName === lastName &&
                item.email === email,
            );
            return {
              selectedName: found
                ? {
                    label: name,
                    value: `${name}||-||${lastName}||-||${email}`,
                  }
                : null,
              searchName: name,
            };
          },
        );

        state.listOfInvoiceryNames = respInvoiceries
          .map(({ name = '', lastName = '', email = '' }) => ({
            label: name || lastName || email,
            value: `${name}||-||${lastName}||-||${email}`,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));

        state.reminders = reminders.map(
          ({ name = '', lastName = '', email = '' }) => {
            const found = respReminders.find(
              (item) =>
                item.name === name &&
                item.lastName === lastName &&
                item.email === email,
            );
            return {
              selectedName: found
                ? {
                    label: name,
                    value: `${name}||-||${lastName}||-||${email}`,
                  }
                : null,
              searchName: name,
            };
          },
        );
        state.shippingMethodDetails =
          response?.shippings && response?.shippings.length > 0
            ? response?.shippings[0].details || {}
            : {};
        state.shippingMethod =
          response?.shippings && response?.shippings.length > 0
            ? response?.shippings[0].method || ''
            : '';
        state.listOfRemainderNames = respReminders
          .map(({ name = '', lastName = '', email = '' }) => ({
            label: name,
            value: `${name}||-||${lastName}||-||${email}`,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));

        state.listOfClientContractsNames = respClientContacts
          .map(({ name = '', lastName = '', email = '', phone = '' }) => ({
            label: name,
            value: `${name}||-||${lastName}||-||${email}||-||${phone}`,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));
        const shippingResponse = response?.shippings;
        const isHaveShippingResponse =
          shippingResponse && shippingResponse.length > 0;

        if (
          isHaveShippingResponse &&
          shippingResponse[0].method &&
          !shippingMethods
        ) {
          onChangeDropdown('shippingMethod')(shippingResponse[0].method);
        }
        if (isHaveShippingResponse && shippingResponse[0].details) {
          dispatch(
            updateOnboardingClientContractShippingDetails(
              shippingResponse[0].details,
            ),
          );
        }
      } catch (error) {
        // TODO add error handling
        console.log('error', error);
      }

      setListOfClients((obj) => ({
        ...obj,
        ...state,
        isFetching: false,
      }));
    };

    setListOfClients((obj) => ({
      ...obj,
      ...initClientContract,
      isFetching: true,
    }));
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      dispatch(clearSelectedClient());
    };
  }, [
    clientId,
    isAdmin,
    signatories.length,
    invoiceries.length,
    reminders.length,
  ]);

  useEffect(() => {
    const message = getError(`data.clients[${selectedClientIdx}].clientId`);
    if (message) {
      notification.open({
        message: 'Warning alert',
        description: message,
        type: 'error',
        icon: <ErrorIcon />,
        className: 'common-error-notification',
      });
      return;
    }
  }, [errorMessages, getError, selectedClientIdx]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    systemInfoApi
      .getActualVAT(moment().format(commonYearDateFormat))
      .then((response: any) => {
        setVatRates(response);
      })
      .catch(() => openErrorNotification('Failed to get VAT'));
  }, []);

  const onChangeSearchByIdx =
    (index: number, rootName: TInitClientContractType, fieldName: string) =>
    (data: string) => {
      if (data === '' && refPrevInput.current?.length !== 1) return;
      const selected = `selected${capitalizeString(fieldName)}`;
      const search = `search${capitalizeString(fieldName)}`;
      refPrevInput.current = data;

      setListOfClients((obj) => ({
        ...obj,
        [`${rootName}`]: obj[`${rootName}`].map((item, idx) =>
          idx === index ? { ...item, [search]: data, [selected]: null } : item,
        ),
      }));

      const value = { [fieldName]: data };
      onChangeValuesByIdxAndName(index, rootName, value);
    };

  const onChangeDropdownByIdx =
    (index: number, rootName: TInitClientContractType) =>
    (data: Record<string, string>) => {
      const values = data?.value.split('||-||');
      if (!values) return;
      const [name, lastName, email, phone] = values;
      const fields = { name, lastName, email, phone };
      const newObj = Object.keys(fields).reduce(
        (acc, key) => ({
          ...acc,
          [`selected${capitalizeString(key)}`]: {
            label: name,
            value: values,
          },
          [`search${capitalizeString(key)}`]: name,
        }),
        {},
      );

      setListOfClients((obj) => ({
        ...obj,
        [`${rootName}`]: obj[`${rootName}`].map((item, idx) =>
          idx === index ? { ...item, ...newObj } : item,
        ),
      }));

      onChangeValuesByIdxAndName(index, rootName, fields);
    };

  const onClearByIdx =
    (index: number, rootName: TInitClientContractType, fieldName: string) =>
    () => {
      const selected = `selected${capitalizeString(fieldName)}`;
      const search = `search${capitalizeString(fieldName)}`;

      setListOfClients((obj) => ({
        ...obj,
        [`${rootName}`]: obj[`${rootName}`].map((item, idx) =>
          idx === index ? { ...item, [search]: '', [selected]: null } : item,
        ),
      }));

      const value = { [fieldName]: '' };
      onChangeValuesByIdxAndName(index, rootName, value);
    };

  const {
    signatories: signatoriesToVerify,
    listOfSignatoryNames,
    invoiceries: invoiceriesToVerify,
    listOfInvoiceryNames,
    reminders: remindersToVerify,
    listOfRemainderNames,
    clientContacts: clientContactToVerify,
    listOfClientContractsNames,
    vatRate: vatRateFromState,
  } = listOfClients;

  const isAdminFlow = isAdmin && clientId;
  const listWithSignatoryLabel = listOfSignatoryNames.filter(
    (item) => item.label,
  );
  const listWithInvoiceryLabel = listOfInvoiceryNames.filter(
    (item) => item.label,
  );
  const listWithRemainderLabel = listOfRemainderNames.filter(
    (item) => item.label,
  );

  const onChangeClientContactInput =
    (index: number) => (event: ChangeEvent<HTMLInputElement> | string) => {
      if (typeof event === 'string') {
        onChangeContractContact(index, event, 'phone');
        return;
      }
      const { value, name } = event.target;
      onChangeContractContact(index, value, name);
    };
  return (
    <Col span={12}>
      {signatories.map(({ name = '', lastName = '', email = '' }, idx) => (
        <React.Fragment key={`${clients[selectedClientIdx]._id}${idx}`}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              {isAdminFlow &&
              signatories.length <= signatoriesToVerify.length &&
              listWithSignatoryLabel.length > 0 &&
              listOfSignatoryNames.length > 0 ? (
                <Dropdown
                  customOption={customOption(listOfSignatoryNames)}
                  label="Signatory Name"
                  allowClear={
                    !!signatoriesToVerify[idx].searchName ||
                    !!signatoriesToVerify[idx].selectedName
                  }
                  labelInValue={true}
                  value={signatoriesToVerify[idx].selectedName}
                  searchValue={signatoriesToVerify[idx].searchName}
                  onClear={onClearByIdx(idx, 'signatories', 'name')}
                  onSearch={onChangeSearchByIdx(idx, 'signatories', 'name')}
                  onChange={onChangeDropdownByIdx(idx, 'signatories')}
                  options={listOfSignatoryNames}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].signatories[${idx}].name`,
                  )}
                />
              ) : (
                <Input
                  name="name"
                  label="Signatory Name"
                  value={name}
                  onChange={onChangeSignatoryByIndex(idx)}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].signatories[${idx}].name`,
                  )}
                />
              )}
            </Col>
            <Col span={8}>
              <Input
                name="lastName"
                label="Signatory Last Name"
                value={lastName}
                onChange={onChangeSignatoryByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].signatories[${idx}].lastName`,
                )}
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                label="Signatory email"
                value={email}
                onChange={onChangeSignatoryByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].signatories[${idx}].email`,
                )}
              />
            </Col>
          </Row>

          {signatories.length > 1 && (
            <Row gutter={[16, 16]} justify="end" align="middle">
              <button
                className="remove-child-btn"
                onClick={onDeleteSignatory(idx)}>
                Delete address
                <TrashIcon />
              </button>
            </Row>
          )}
        </React.Fragment>
      ))}
      <button
        className="add-common-btn client-contract"
        onClick={onAddNewSignatory}>
        <CrossIcon />
        Add new Signatory
      </button>
      <br />
      <span className="help-info-label">
        Enter the name and email address of the person at the client who needs
        to receive Numeriq invoices
      </span>
      <br />
      <ErrorMessage
        errorMessage={getError(
          `data.clients[${convertedSelectedIdx}].invoiceries`,
        )}
      />
      {invoiceries.map(({ name = '', lastName = '', email = '' }, idx) => (
        <React.Fragment key={`${clients[selectedClientIdx]._id}${idx}`}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              {isAdminFlow &&
              listWithInvoiceryLabel.length > 0 &&
              invoiceries.length <= invoiceriesToVerify.length &&
              listOfInvoiceryNames.length > 0 ? (
                <Dropdown
                  customOption={customOption(listOfInvoiceryNames)}
                  label="Invoicing Name"
                  allowClear={
                    !!invoiceriesToVerify[idx].searchName ||
                    !!invoiceriesToVerify[idx].selectedName
                  }
                  labelInValue={true}
                  value={invoiceriesToVerify[idx].selectedName}
                  searchValue={invoiceriesToVerify[idx].searchName}
                  onClear={onClearByIdx(idx, 'invoiceries', 'name')}
                  onSearch={onChangeSearchByIdx(idx, 'invoiceries', 'name')}
                  onChange={onChangeDropdownByIdx(idx, 'invoiceries')}
                  options={listOfInvoiceryNames}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].invoiceries[${idx}].name`,
                  )}
                />
              ) : (
                <Input
                  name="name"
                  label="Invoicing Name"
                  value={name}
                  onChange={onChangeInvoiceryByIndex(idx)}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].invoiceries[${idx}].name`,
                  )}
                />
              )}
            </Col>
            <Col span={8}>
              <Input
                name="lastName"
                label="Invoicing Last Name"
                value={lastName}
                onChange={onChangeInvoiceryByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].invoiceries[${idx}].lastName`,
                )}
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                label="Invoicing email"
                value={email}
                onChange={onChangeInvoiceryByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].invoiceries[${idx}].email`,
                )}
              />
            </Col>
          </Row>

          {invoiceries.length > 1 && (
            <Row gutter={[16, 16]} justify="end" align="middle">
              <button
                className="remove-child-btn"
                onClick={onDeleteInvoicery(idx)}>
                Delete address
                <TrashIcon />
              </button>
            </Row>
          )}
        </React.Fragment>
      ))}
      <button
        className="add-common-btn client-contract"
        onClick={onAddNewInvoicery}>
        <CrossIcon />
        Add new Invoicing
      </button>
      <br />
      <span className="help-info-label">
        Enter the name and email address of the person who we should escalate to
        if an invoice doesn’t get paid
      </span>
      <br />
      <ErrorMessage
        errorMessage={getError(
          `data.clients[${convertedSelectedIdx}].reminders`,
        )}
      />
      {reminders.map(({ name = '', lastName = '', email = '' }, idx) => (
        <React.Fragment key={`${clients[selectedClientIdx]._id}${idx}`}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              {isAdminFlow &&
              listWithRemainderLabel.length > 0 &&
              reminders.length <= remindersToVerify.length &&
              listOfRemainderNames.length > 0 ? (
                <Dropdown
                  customOption={customOption(listOfRemainderNames)}
                  label="Reminder Name"
                  allowClear={
                    !!remindersToVerify[idx].searchName ||
                    !!remindersToVerify[idx].selectedName
                  }
                  labelInValue={true}
                  value={remindersToVerify[idx].selectedName}
                  searchValue={remindersToVerify[idx].searchName}
                  onClear={onClearByIdx(idx, 'reminders', 'name')}
                  onSearch={onChangeSearchByIdx(idx, 'reminders', 'name')}
                  onChange={onChangeDropdownByIdx(idx, 'reminders')}
                  options={listOfRemainderNames}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].reminders[${idx}].name`,
                  )}
                />
              ) : (
                <Input
                  name="name"
                  label="Reminder Name"
                  value={name}
                  onChange={onChangeReminderByIndex(idx)}
                  errorMessage={getError(
                    `data.clients[${convertedSelectedIdx}].reminders[${idx}].name`,
                  )}
                />
              )}
            </Col>
            <Col span={8}>
              <Input
                name="lastName"
                label="Reminder Last Name"
                value={lastName}
                onChange={onChangeReminderByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].reminders[${idx}].lastName`,
                )}
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                label="Reminder email"
                value={email}
                onChange={onChangeReminderByIndex(idx)}
                errorMessage={getError(
                  `data.clients[${convertedSelectedIdx}].reminders[${idx}].email`,
                )}
              />
            </Col>
          </Row>
          {reminders.length > 1 && (
            <Row gutter={[16, 16]} justify="end" align="middle">
              <button
                className="remove-child-btn"
                onClick={onDeleteReminder(idx)}>
                Delete address
                <TrashIcon />
              </button>
            </Row>
          )}
        </React.Fragment>
      ))}
      <button
        className="add-common-btn client-contract"
        onClick={onAddNewReminder}>
        <CrossIcon />
        Add new Reminder
      </button>
      {isAdmin &&
        clientContacts.map(({ name, lastName, email, phone }, idx) => {
          return (
            <Fragment key={idx}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={12}>
                  {clientContractOptions[0].name ? (
                    <Dropdown
                      customOption={customOption(listOfClientContractsNames)}
                      label="Client contact Name"
                      allowClear
                      labelInValue={true}
                      value={clientContactToVerify[idx].selectedName || name}
                      searchValue={clientContactToVerify[idx].searchName}
                      onClear={onClearByIdx(idx, 'clientContacts', 'name')}
                      onSearch={onChangeSearchByIdx(
                        idx,
                        'clientContacts',
                        'name',
                      )}
                      onChange={onChangeDropdownByIdx(idx, 'clientContacts')}
                      options={listOfClientContractsNames}
                      errorMessage={
                        getError(
                          `data.clients[${convertedSelectedIdx}].clientContacts[${idx}].name`,
                        ) ||
                        getError(
                          `data.clients[${convertedSelectedIdx}].clientContacts`,
                          true,
                        )
                      }
                      showAsterisk
                    />
                  ) : (
                    <Input
                      name="name"
                      label="Client contact Name"
                      value={name}
                      onChange={onChangeClientContactInput(idx)}
                      errorMessage={
                        getError(
                          `data.clients[${convertedSelectedIdx}].clientContacts[${idx}].name`,
                        ) ||
                        getError(
                          `data.clients[${convertedSelectedIdx}].clientContacts`,
                          true,
                        )
                      }
                      showAsterisk
                    />
                  )}
                </Col>
                <Col span={12}>
                  <Input
                    name="lastName"
                    label="Client contact Last Name"
                    value={lastName}
                    onChange={onChangeClientContactInput(idx)}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts[${idx}].lastName`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts`,
                        true,
                      )
                    }
                    showAsterisk
                  />
                </Col>
              </Row>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={12}>
                  <Input
                    name="email"
                    label="Client contact Email"
                    value={email}
                    onChange={onChangeClientContactInput(idx)}
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts[${idx}].email`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts`,
                        true,
                      )
                    }
                    showAsterisk
                  />
                </Col>
                <Col span={12}>
                  <Input
                    inputType="phone"
                    value={phone}
                    onChange={onChangeClientContactInput(idx)}
                    label="Client contact Phone number"
                    errorMessage={
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts[${idx}].phone`,
                      ) ||
                      getError(
                        `data.clients[${convertedSelectedIdx}].clientContacts`,
                        true,
                      )
                    }
                    showAsterisk
                  />
                </Col>
              </Row>
            </Fragment>
          );
        })}
      {isAdmin && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Textarea
              label="Comment from client or contract"
              value={clientOrContractComment}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].clientOrContractComment`,
              )}
              showAsterik
              onChange={(e) => onChangeTextarea(e.target.value)}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24 / (isShowShippingMethodDetails ? 2 : 1)}>
          <Dropdown
            options={shippingMethodsList}
            label="Invoice shipping method"
            value={
              shippingMethod.charAt(0).toUpperCase() + shippingMethod.slice(1)
            }
            onChange={onChangeDropdown('shippingMethod')}
            showSearch={false}
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].shippingMethod`,
            )}
          />
        </Col>
        {shippingMethod === 'portal' && (
          <Col span={12}>
            <Input
              name="portalUrl"
              label="Portal URL"
              value={portalUrl}
              onChange={onChangeShippingDetails}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].shippingMethodDetails.portalUrl`,
                true,
              )}
            />
          </Col>
        )}
        {shippingMethod === 'by post' && (
          <Col span={12}>
            <Input
              name="postalAddress"
              label="Postal address"
              value={postalAddress}
              onChange={onChangeShippingDetails}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].shippingMethodDetails.postalAddress`,
                true,
              )}
            />
          </Col>
        )}
      </Row>
      {shippingMethod === 'by post' && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input
              name="firstName"
              label="First name"
              value={firstName}
              onChange={onChangeShippingDetails}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].shippingMethodDetails.firstName`,
                true,
              )}
            />
          </Col>
          <Col span={12}>
            <Input
              name="lastName"
              label="Last name"
              value={lastName}
              onChange={onChangeShippingDetails}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].shippingMethodDetails.lastName`,
                true,
              )}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ height: '54px' }}>
        <Col span={12}>
          <CommonDatePicker
            label="Start date"
            defaultValue={startDate || ''}
            changeDate={onChangeDate('startDate')}
            className="date-picker-form"
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].startDate`,
            )}
            calendarPosition="right-calendar-position"
          />
        </Col>
        <Col span={12}>
          <CommonDatePicker
            label="End date"
            defaultValue={endDate || ''}
            changeDate={onChangeDate('endDate')}
            className="date-picker-form"
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].endDate`,
            )}
            disabled={indefinite}
            calendarPosition="right-calendar-position"
          />
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginTop: '2rem' }}>
        <Col span={12}>
          <span>Indefinite</span>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Switch
            checked={indefinite}
            onChange={() => onChangeSwitch('indefinite')}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={14}>
          <span>Work Type:</span>
        </Col>
        <Col span={10}>
          <Radio.Group
            className="radio-group-space-around"
            name="workType"
            value={workType === 'Full time'}
            onChange={onChangeWorkType}>
            <Radio value={true}>Full time</Radio>
            <Radio value={false}>Part time</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={wageTypeOptions}
            label="Wage type"
            value={wageType}
            onChange={onChangeDropdown('wageType')}
            showSearch={false}
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].wageType`,
            )}
            allowClear
            onClear={() => onChangeDropdown('wageType')('')}
          />
        </Col>
      </Row>
      {wageType === 'Timesheet' && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={timesheetSubmissionArray}
              label="Timesheet submission"
              value={timesheetSubmission}
              onChange={onChangeDropdown('timesheetSubmission')}
              showSearch={false}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].timesheetSubmission`,
              )}
              allowClear
              onClear={() => onChangeDropdown('timesheetSubmission')('')}
            />
          </Col>
        </Row>
      )}
      {workType !== 'Full time' && (
        <Row gutter={[16, 16]}>
          <Col span={14}>
            <div style={{ marginTop: 18 }}>
              <span>How many hours per week?</span>
            </div>
          </Col>
          <Col span={10}>
            <Input
              name="workHoursPerWeek"
              label="hours"
              value={workHoursPerWeek}
              onChange={onChange}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].workHoursPerWeek`,
              )}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Dropdown
            options={paymentTermOption(
              clientPaymentTerm as TPaymentTermsList,
              clientPaymentTermRange,
            )}
            label="Payment term"
            value={paymentTerm}
            searchValue={paymentTerm}
            showSearch={true}
            onChange={onChangeDropdown('paymentTerm')}
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].paymentTerm`,
            )}
            hideCursorBlink
            allowClear
            onClear={() => onChangeDropdown('paymentTerm')('')}
          />
        </Col>
      </Row>
      {isAdmin && vatRates.length > 0 && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Dropdown
              options={vatRates.map((item) => {
                return {
                  value: item._id,
                  label: `${item.name || ''}   ${item.percentage || '0'}%`,
                };
              })}
              label="VAT rate"
              value={vatRate || vatRateFromState}
              onChange={onChangeDropdown('vatRate')}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].vatRate`,
              )}
              hideCursorBlink
              allowClear
              onClear={() => {
                onChangeDropdown('vatRate')('');
                setListOfClients((obj) => {
                  return { ...obj, vatRate: '' };
                });
              }}
              showAsterisk
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={14}>
          <div style={{ marginTop: 18 }}>
            <span>№ of days for payment terms</span>
            <PopoverHelpInfo
              title={
                <p>
                  Is the invoice payment due X number of days from the end of
                  the month or X number of days from the send date=NET
                </p>
              }
            />
          </div>
        </Col>
        <Col span={10}>
          <Input
            name="paymentTermRange"
            label="days"
            value={paymentTermRange}
            onChange={onChange}
            errorMessage={getError(
              `data.clients[${convertedSelectedIdx}].paymentTermRange`,
            )}
          />
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ height: 54 }}>
        <Col span={14}>
          <span>Reimbursable expenses:</span>
          <Popover
            className="info-pop-button"
            placement="rightTop"
            content={<p>Receipts mandatory</p>}
            trigger="hover"
            overlayClassName="info-pop-content"
            showArrow={false}>
            <button>
              <WarningIcon />
            </button>
          </Popover>
        </Col>
        <Col span={10}>
          <Radio.Group
            className="radio-group-space-around"
            name="reimbursableExpenses"
            value={reimbursableExpenses}
            onChange={onChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {isAdmin && (
        <Row gutter={16}>
          <Col span={24}>
            <Input
              name="contractComment"
              label="Contract comment"
              value={contractComment}
              onChange={onChange}
              errorMessage={getError(
                `data.clients[${convertedSelectedIdx}].contractComment`,
              )}
              showAsterisk
            />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default ClientContractTabInformationSection;
