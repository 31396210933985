import { FC } from 'react';
import { Col, Row } from 'antd';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';

interface IProps {
  inputValues: Pick<
    ILeadDto,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'phone'
    | 'howOld'
    | 'countryResident'
    | 'countryWorkResident'
    | 'cantonLiveResident'
    | 'cantonWorkResident'
    | 'maritalStatus'
    | 'isSpouseWork'
    | 'numberOfDependantChildren'
    | 'nationality'
    | 'numberOfDependantTeenagers'
    | 'dateOfBirth'
  >;
}

const PersonalInfoViewerComponent: FC<IProps> = ({ inputValues }) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    howOld,
    nationality,
    countryResident,
    countryWorkResident,
    cantonLiveResident,
    cantonWorkResident,
    maritalStatus,
    isSpouseWork,
    numberOfDependantChildren,
    numberOfDependantTeenagers,
    dateOfBirth,
  } = inputValues;

  return (
    <Col span={12} className="personal-info">
      <h3>Personal information</h3>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Name</span>
            <span className="item-value">{firstName}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Last name</span>
            <span className="item-value">{lastName}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Email</span>
            <span className="item-value">{email}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Phone number</span>
            <span className="item-value">{phone}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Date of birth</span>
            <span className="item-value">{dateOfBirth}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Date of birth (form)</span>
            <span className="item-value">{howOld}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Country of LIVING</span>
            <span className="item-value">{countryResident}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Country of WORKING</span>
            <span className="item-value">{countryWorkResident}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="personal-info">
            <span className="item-label">Citizenship</span>
            <span className="item-value">{nationality}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Canton of LIVING</span>
            <span className="item-value">{cantonLiveResident}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Canton of WORKING</span>
            <span className="item-value">{cantonWorkResident}</span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Marital status</span>
            <span className="item-value">{maritalStatus}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">Working spouse</span>
            <span className="item-value">{isSpouseWork}</span>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">
              Dependant children up to 12 years
            </span>
            <span className="item-value">{numberOfDependantChildren}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="personal-info">
            <span className="item-label">
              Dependant children from 12 to 25 years
            </span>
            <span className="item-value">{numberOfDependantTeenagers}</span>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default PersonalInfoViewerComponent;
