import { FC, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getLeadById } from '../../redux/reducers/waiting-room/asyncThunkActions';

import './EditLeadPage.less';
import PersonalInfoComponent from './componsnts/PersonalInfoComponent';
import WorkInfoComponent from './componsnts/WorkInfoComponent';
import {
  changeLeadDetails,
  clearSelectedLead,
  updateIsUpdateLeadDetails,
} from '../../redux/reducers/waiting-room/sliceReducer';
import PersonalInfoViewerComponent from './componsnts/PersonalInfoViewerComponent';

const EditLeadPage: FC = () => {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();

  const { leadDetails, errorMessages, isShowUserDetails } = useAppSelector(
    (state) => state.waitingRoom.selectedLead,
  );

  const {
    swissWorkPermit,
    permitType,
    salaryRateNoVAT,
    salaryType,
    currency,
    workingDaysHours,
    comments,
    notes,
    firstName,
    lastName,
    email,
    phone,
    howOld,
    countryResident,
    countryWorkResident,
    cantonLiveResident,
    cantonWorkResident,
    maritalStatus,
    isSpouseWork,
    numberOfDependantChildren,
    nationality,
    numberOfDependantTeenagers,
    dateOfBirth,
  } = leadDetails;

  useEffect(() => {
    dispatch(getLeadById(leadId!));

    return () => {
      dispatch(clearSelectedLead());
    };
  }, []);

  const changeFormValue = (name: string, value: string): void => {
    dispatch(updateIsUpdateLeadDetails(true));
    dispatch(changeLeadDetails({ [name]: value }));
  };

  const getError = (fieldName: string): string =>
    errorMessages ? errorMessages[fieldName] ?? '' : '';

  if (isShowUserDetails) {
    return (
      <PersonalInfoViewerComponent
        inputValues={{
          firstName,
          lastName,
          email,
          phone,
          howOld,
          countryResident,
          countryWorkResident,
          cantonLiveResident,
          cantonWorkResident,
          maritalStatus,
          isSpouseWork,
          numberOfDependantChildren,
          nationality,
          numberOfDependantTeenagers,
          dateOfBirth,
        }}
      />
    );
  }

  return (
    <div className="edit-lead-page">
      <Row gutter={[32, 0]}>
        <PersonalInfoComponent
          onChangeDetails={changeFormValue}
          getError={getError}
          inputValues={{
            firstName,
            lastName,
            email,
            phone,
            howOld,
            countryResident,
            countryWorkResident,
            cantonLiveResident,
            cantonWorkResident,
            maritalStatus,
            isSpouseWork,
            numberOfDependantChildren,
            nationality,
            numberOfDependantTeenagers,
            dateOfBirth,
          }}
        />
        <WorkInfoComponent
          onChangeDetails={changeFormValue}
          getError={getError}
          inputValues={{
            swissWorkPermit,
            permitType,
            salaryRateNoVAT,
            salaryType,
            currency,
            workingDaysHours,
            comments,
            notes,
          }}
        />
      </Row>
    </div>
  );
};

export default EditLeadPage;
