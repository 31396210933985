import { ChangeEvent, FC } from 'react';
import { Col, Row } from 'antd';
import Input from '../../../components/input/Input';
import { ILeadDto } from '../../../common/api/services/LeadApi/types/ILeadDto';
import CopyIcon from '../../../assets/icons/CopyIcon';
import { onCopyByClick } from '../../../common/utils/onCopyByClick';
import {
  updateIsUpdateLeadDetails,
  updateLeadBirthDate,
} from '../../../redux/reducers/waiting-room/sliceReducer';
import { useAppDispatch } from '../../../redux/store';
import CommonDatePicker from '../../../components/date-picker/CommonDatePicker';
import moment from '../../../common/constants/moment';
import { commonYearDateFormat } from '../../../common/constants/dateConstants';

interface IProps {
  inputValues: Pick<
    ILeadDto,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'phone'
    | 'howOld'
    | 'countryResident'
    | 'countryWorkResident'
    | 'cantonLiveResident'
    | 'cantonWorkResident'
    | 'maritalStatus'
    | 'isSpouseWork'
    | 'numberOfDependantChildren'
    | 'nationality'
    | 'numberOfDependantTeenagers'
    | 'dateOfBirth'
  >;
  onChangeDetails: (name: string, value: string) => void;
  getError: (fileName: string) => string;
}

const PersonalInfoComponent: FC<IProps> = ({
  inputValues,
  onChangeDetails,
  getError,
}) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    howOld,
    nationality,
    countryResident,
    countryWorkResident,
    cantonLiveResident,
    cantonWorkResident,
    maritalStatus,
    isSpouseWork,
    numberOfDependantChildren,
    numberOfDependantTeenagers,
    dateOfBirth,
  } = inputValues;

  const dispatch = useAppDispatch();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    onChangeDetails(name, value);
  };

  const onChangePhone = (value: string) => {
    onChangeDetails('phone', value);
  };

  const onChangeBirthDate = (date: Date | null): void => {
    const value = date ? moment(date)?.format(commonYearDateFormat) : null;
    dispatch(
      updateLeadBirthDate(
        value ? moment(value).format(commonYearDateFormat) : null,
      ),
    );
    dispatch(updateIsUpdateLeadDetails(true));
  };

  return (
    <Col span={12} className="personal-info">
      <h3>Personal information</h3>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="firstName"
            value={firstName}
            onChange={onChange}
            label="Name"
            errorMessage={getError('firstName')}
          />
        </Col>
        <Col span={12}>
          <Input
            name="lastName"
            value={lastName}
            onChange={onChange}
            label="Last name"
            errorMessage={getError('lastName')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="email"
            value={email}
            onChange={onChange}
            label="Email"
            errorMessage={getError('email')}
          />

          <CopyIcon
            className="copy-icon"
            onClick={() => onCopyByClick(email)}
          />
        </Col>
        <Col span={12}>
          <Input
            inputType="phone"
            value={phone}
            onChange={onChangePhone}
            label="Phone number"
            errorMessage={getError('phone')}
            placeholder="+XX XXX XXX XX XX"
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <CommonDatePicker
            label="Date of birth"
            defaultValue={dateOfBirth || ''}
            changeDate={onChangeBirthDate}
            className="date-picker-form"
            errorMessage={getError('dateOfBirth')}
            calendarPosition="right-calendar-position"
          />
        </Col>
        <Col span={12}>
          <Input
            name="howOld"
            value={howOld}
            onChange={onChange}
            label="Date of birth (form)"
            errorMessage={getError('howOld')}
          />

          <CopyIcon
            className="copy-icon"
            onClick={() => onCopyByClick(howOld)}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <Input
            name="countryResident"
            value={countryResident}
            onChange={onChange}
            label="Country of LIVING"
            errorMessage={getError('countryResident')}
          />
        </Col>
        <Col span={8}>
          <Input
            name="countryWorkResident"
            value={countryWorkResident}
            onChange={onChange}
            label="Country of WORKING"
            errorMessage={getError('countryWorkResident')}
          />
        </Col>
        <Col span={8}>
          <Input
            name="nationality"
            value={nationality}
            onChange={onChange}
            label="Citizenship"
            errorMessage={getError('nationality')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="cantonLiveResident"
            value={cantonLiveResident}
            onChange={onChange}
            label="Canton of LIVING"
            errorMessage={getError('cantonLiveResident')}
          />
        </Col>
        <Col span={12}>
          <Input
            name="cantonWorkResident"
            value={cantonWorkResident}
            onChange={onChange}
            label="Canton of WORKING"
            errorMessage={getError('cantonWorkResident')}
          />
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Input
            name="maritalStatus"
            value={maritalStatus}
            onChange={onChange}
            label="Marital status"
            errorMessage={getError('maritalStatus')}
          />
        </Col>
        <Col span={12}>
          <Input
            name="isSpouseWork"
            value={isSpouseWork}
            onChange={onChange}
            label="Working spouse"
            errorMessage={getError('isSpouseWork')}
          />
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Input
            inputType="number"
            name="numberOfDependantChildren"
            value={numberOfDependantChildren}
            onChange={onChange}
            label="Dependant children up to 12 years"
            errorMessage={getError('numberOfDependantChildren')}
          />
        </Col>
        <Col span={12}>
          <Input
            inputType="number"
            name="numberOfDependantTeenagers"
            value={numberOfDependantTeenagers}
            onChange={onChange}
            label="Dependant children from 12 to 25 years"
            errorMessage={getError('numberOfDependantTeenagers')}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default PersonalInfoComponent;
