import {
  IClientContract,
  IWorkContract,
} from '../../redux/reducers/onboarding/types';
import {
  commonMonthTimeFormat,
  commonYearDateFormat,
} from '../constants/dateConstants';
import moment from '../constants/moment';

export const checkWorkContract = (workContractFromState: IWorkContract) => {
  return {
    ...workContractFromState,
    useExpensesForOptimization:
      workContractFromState.useExpensesForOptimization ?? false,
    companies: workContractFromState.companies.map((company) => {
      const state = {
        ...company,
        lossOfIncome: company.lossOfIncome ?? 0,
        hasAdditionalAccidentInsurance:
          company.hasAdditionalAccidentInsurance ?? true,
        indefinite: company.indefinite ?? true,
        overtimeAllowed: company.overtimeAllowed ?? true,
        doubleTimeAllowed: company.doubleTimeAllowed ?? true,
        hasTreasury: company.hasTreasury ?? false,
        hasSalaryAmount: company.hasSalaryAmount ?? false,
        professionalAccident: company.professionalAccident ?? 0.2,
        nonProfessionalAccident: company.nonProfessionalAccident ?? 0.99,
        hasCBA: company.hasCBA ?? false,
        otherJob: {
          ...company.otherJob,
          active: company.otherJob?.active ?? true,
          isPrimaryJob: company.otherJob?.isPrimaryJob ?? true,
          numeriqTime: company.otherJob?.numeriqTime ?? 0,
        },
        receivedWorkContract: company.receivedWorkContract ?? false,
        hasRepresentativeAllowance: company.hasRepresentativeAllowance ?? false,
        invoiceCurrency: company.invoiceCurrency || '',
      };

      if (!company.clientId) delete state.clientId;

      return state;
    }),
  };
};

const initializeObject = (name = '', lastName = '', email = '') => ({
  name,
  lastName,
  email,
});

export const checkClientContract = (
  clientContractFromState: IClientContract,
  workContractFromState: IWorkContract | undefined = undefined,
) => {
  return {
    ...clientContractFromState,
    clients: clientContractFromState.clients.map((client, idx) => {
      const state = {
        ...client,
        indefinite: client.indefinite ?? true,
        workType: client.workType ?? 'Full time',
        reimbursableExpenses: client.reimbursableExpenses ?? true,
        isAgencyInvolved: client.isAgencyInvolved ?? false,
        receivedClientContract: client.receivedClientContract ?? false,
        agencyConfirmed: client.agencyConfirmed ?? false,
        shippingMethodDetails: client.shippingMethodDetails ?? {},
        shippingMethod: client.shippingMethod || 'email',
        agencyMargin: client.isAgencyInvolved ? client.agencyMargin : 0,
        signatories:
          client.signatories
            ?.filter((item) => item)
            .map((signatory) =>
              initializeObject(
                signatory?.name,
                signatory?.lastName,
                signatory?.email,
              ),
            ) || initializeObject(),
        invoiceries:
          client.invoiceries
            ?.filter((item) => item)
            .map((invoicery) =>
              initializeObject(
                invoicery?.name,
                invoicery?.lastName,
                invoicery?.email,
              ),
            ) || initializeObject(),
        reminders:
          client.reminders
            ?.filter((item) => item)
            .map((reminder) =>
              initializeObject(
                reminder?.name,
                reminder?.lastName,
                reminder?.email,
              ),
            ) || initializeObject(),
        wageType: client.wageType,
        purchaseOrders: client.enabledPurchaseOrders
          ? client.purchaseOrders.map((item) => {
              return {
                ...item,
                endDate: item.endDate
                  ? moment(item.endDate).format(commonYearDateFormat)
                  : null,
                startDate: item.startDate
                  ? moment(item.startDate).format(commonYearDateFormat)
                  : null,
              };
            })
          : [],
        enabledPurchaseOrders: client.enabledPurchaseOrders || false,
      };

      // const { clientId } = workContractFromState?.companies[idx] || {};
      if (!client.clientId) {
        delete state.clientId;
      } else {
        state.clientId = client.clientId;
      }

      return state;
    }),
  };
};
